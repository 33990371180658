import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import WatchElementAnimate from '../../components/watch-element-animate'
import BGLeft from '../../images/Hexagon-faded-left.svg'
import BGRight from '../../images/Hexagon-faded-right.svg'

const FeaturesWrapper = styled.div`
  position: relative;
  z-index: 1;
  .bg-left {
    position: absolute;
    left: 0;
    bottom: -350px;
    z-index: 0;
  }
  .bg-right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
  @media (max-width: 640px) {
    h2 {
      margin-bottom: 15px;
      span {
        display: block;
      }
    }
  }
  @media (max-width: 768px) {
    .bg-left {
      display: none;
    }
    .bg-right {
      display: none;
    }
  }
`
const Features = ({ features, items }) => {
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <WatchElementAnimate
      className={'.index__features__animated'}
      animateName={'animate__fadeInUp'}>
      <FeaturesWrapper className="index__features__animated">
        <img src={BGLeft} className="bg-left" />
        <img src={BGRight} className="bg-right" />
        <div tw="container mb-16 md:mb-0 pt-8 md:p-16">
          <div tw="md:flex flex-row text-center md:text-left">
            <div tw="md:max-w-xs">
              <div
                dangerouslySetInnerHTML={{
                  __html: features.headline.html,
                }}
                tw="text-navy"></div>
            </div>
            <div tw="md:max-w-md md:ml-16 md:pt-4">
              <p tw="text-xl text-ocean">{features.feature_description}</p>
            </div>
          </div>
          <div tw="hidden md:grid grid-cols-1 md:grid-cols-3 gap-2">
            {items.map((item) => {
              return (
                <div tw="px-12 pt-12 pb-11 bg-white z-10">
                  <div>
                    <Img fixed={item.card_icon.fixed} />
                  </div>
                  <div tw="pb-4 pt-5">
                    <p tw="text-lg text-navy font-black">
                      {item.card_headline}
                    </p>
                  </div>
                  <div>
                    <p tw="text-lg text-ocean">{item.card_description}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <Slider {...settings} tw="md:hidden">
            {items.map((item, key) => {
              return (
                <div tw="px-8 pt-12 pb-11" key={key}>
                  <div>
                    <Img fixed={item.card_icon.fixed} />
                  </div>
                  <div tw="pb-4 pt-5">
                    <p tw="text-lg text-navy font-black">
                      {item.card_headline}
                    </p>
                  </div>
                  <div>
                    <p tw="text-lg text-ocean">{item.card_description}</p>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </FeaturesWrapper>
    </WatchElementAnimate>
  )
}
export default Features
