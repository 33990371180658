import React from 'react'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import WatchElementAnimate from '../../components/watch-element-animate'
import Slider from 'react-slick'

const Partners = ({ partner, items }) => {
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <WatchElementAnimate
      className={'.index__partners__animated'}
      animateName={'animate__fadeInUp'}>
      <div
        tw="container py-8 text-center md:py-14"
        className="index__partners__animated">
        <p tw="text-ocean text-lg">{partner.headline.text}</p>
        <div tw="hidden md:grid md:grid-cols-5 md:justify-between">
          {items.map((item, key) => {
            return (
              <div key={key}>
                <Img fluid={item.logo.fluid} />
              </div>
            )
          })}
        </div>
        <Slider {...settings} tw="md:hidden">
          {items.map((item, key) => {
            return (
              <div key={key}>
                <Img fluid={item.logo.fluid} />
              </div>
            )
          })}
        </Slider>
      </div>
    </WatchElementAnimate>
  )
}
export default Partners
