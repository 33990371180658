import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import WatchElementAnimate from '../../components/watch-element-animate'
import { Link } from 'gatsby'
import { SmallSolidTealButton } from '../../utils/styles'
import i18n from '../../../config/i18n'

const WhoWeAreWrapper = styled.div`
  .who-photo {
    width: 400px;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  .who-info {
    @media (min-width: 1024px) {
      max-width: 386px;
    }
  }
`

const WhoWeAre = ({ who, i18n }) => {
  return (
    <WatchElementAnimate
      className={'.index__who__animated'}
      animateName={'animate__fadeInUp'}>
      <WhoWeAreWrapper
        tw="container pb-16 text-center lg:pb-28"
        className="index__who__animated">
        <h2 tw="mb-5 md:mb-10 text-navy">{who.top_headline.text}</h2>
        <div tw="lg:flex">
          <div tw="lg:flex-none" className="who-photo">
            <Img
              fluid={who.image.fluid}
              tw="rounded-t-lg md:rounded-l-lg md:rounded-tr-none"
            />
          </div>
          <div tw="xl:py-16 md:flex-grow bg-air justify-self-center text-navy pt-8 py-8 px-8 xl:pt-14 xl:px-24 xl:pb-0 rounded-b-xl md:rounded-b-none md:rounded-r-xl text-center md:text-left">
            <div tw="lg:flex justify-between mt-8 text-center lg:text-left">
              <div className="who-info">
                <h3>{who.headline.text}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: who.description.html,
                  }}
                  tw="text-ocean mt-5"></div>
              </div>
              <div tw="mt-4 lg:mt-0 lg:flex lg:items-center">
                <Link to={who.link.url}>
                  <SmallSolidTealButton>
                    {i18n.buttons.learn_more}
                  </SmallSolidTealButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </WhoWeAreWrapper>
    </WatchElementAnimate>
  )
}
export default WhoWeAre
