import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Check from '../../images/Check.svg'
import {SmallSolidTealButton} from "../../utils/styles";
import LocalizedLink from "../localized-link";
const InnovationWrapper = styled.div`
  p {
    margin-bottom: 1rem;
  }

  .list-html {
    text-align: left;
    li {
      margin-bottom: 10px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-box;
      align-items: center;
      gap: 0.25rem;
    }
    li::before {
      content: '';
      display: block;
      margin-right: 12px;
      background-size: cover;
      background-image: url(${Check});
      width: 16px;
      height: 16px;
      padding: 0 9px;
    }
  }
`

const Innovation = ({ item, flow, i18n }) => {
  const styles = {
    row: tw`md:flex flex-row`,
    reverse: tw`md:flex flex-row-reverse`,
  }
  return (
    <InnovationWrapper tw="container pt-20 pb-8 text-center md:text-left md:pt-28 md:pb-14">
      <div css={styles[flow]}>
        <div tw="md:flex-none max-w-lg flex items-center">
          <div>
            <h3 tw="text-navy font-black">{item.headline.text}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: item.description.html,
              }}
              tw="text-ocean mt-5 text-lg text-left"></div>
            <LocalizedLink to="/request-meeting">
              <SmallSolidTealButton tw="mt-8">
                {i18n.buttons.meeting}
              </SmallSolidTealButton>
            </LocalizedLink>
          </div>
        </div>
        <div tw="md:flex-grow pt-8 md:pt-0">
          <Img fluid={item.image.fluid} />
        </div>
      </div>
    </InnovationWrapper>
  )
}
export default Innovation
