import React from 'react'
import tw, { styled } from 'twin.macro'
import WatchElementAnimate from '../../components/watch-element-animate'
import HexagonCheck from '../../images/Hexagon-Check.svg'

const BenefitsWrapper = styled.div`
  padding: 50px 0;
  div {
    img {
      width: 50px;
      height: 50px;
    }
  }
  .benefits-text {
    strong {
      display: block;
    }
  }
  .vl {
    border-left: 1px solid #d8d8d8;
    height: 50px;
  }
  @media (max-width: 767px) {
    padding: 25px 0 25px;
    .vl {
      display: none;
    }
    .benefits-text {
      strong {
        display: inline;
      }
    }
  }
`

const Benefits = ({ benefits }) => {
  return (
    <WatchElementAnimate
      className={'.index__benefits__animated'}
      animateName={'animate__fadeInUp'}>
      <BenefitsWrapper
        tw="container md:flex flex-col md:flex-row justify-around"
        className="index__benefits__animated">
        <div tw="md:flex text-center pt-8 md:pt-0 md:text-left items-center">
          <img src={HexagonCheck} className="check-icon" tw="md:mr-8 m-auto" />

          <div
            dangerouslySetInnerHTML={{
              __html: benefits.items[0].text.html,
            }}
            tw="text-xl text-navy"
            className="benefits-text"></div>
        </div>
        <div class="vl"></div>
        <div tw="md:flex text-center pt-14 md:pt-0 md:text-left items-center">
          <img src={HexagonCheck} className="check-icon" tw="md:mr-8 m-auto" />

          <div
            dangerouslySetInnerHTML={{
              __html: benefits.items[1].text.html,
            }}
            tw="text-xl text-navy"
            className="benefits-text"></div>
        </div>
        <div class="vl"></div>
        <div tw="md:flex text-center pt-14 md:pt-0 md:text-left items-center">
          <img src={HexagonCheck} className="check-icon" tw="md:mr-8 m-auto" />

          <div
            dangerouslySetInnerHTML={{
              __html: benefits.items[2].text.html,
            }}
            tw="text-xl text-navy"
            className="benefits-text"></div>
        </div>
      </BenefitsWrapper>
    </WatchElementAnimate>
  )
}
export default Benefits
