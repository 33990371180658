import React from 'react'
import tw, { styled } from 'twin.macro'
import Check from '../../images/Check.svg'
import WatchElementAnimate from '../../components/watch-element-animate'
import { SmallSolidTealButton } from '../../utils/styles'
import LocalizedLink from '../localized-link'
const IntroWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  .intro-description {
    li {
      color: #263c5c;
      padding-bottom: 1rem;
      font-weight: 900;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
    ul {
      li::before {
        content: '';
        display: block;
        margin-right: 12px;
        background-size: cover;
        background-image: url(${Check});
        width: 16px;
        height: 16px;
        padding: 0 9px;
      }
    }
  }
  @media (max-width: 640px) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  @media (min-width: 1024px) {
    .intro-image {
      img {
        max-height: 570px;
        margin-top: -50px;
      }
    }
  }
`

const Intro = ({ intro, bg }) => {
  return (
    <WatchElementAnimate
      className={'.index__intro__animated'}
      animateName={'animate__fadeInUp'}>
      <IntroWrapper
        tw="container lg:grid lg:grid-cols-2"
        className="functionalities index__intro__animated">
        <div>
          <div style={{ maxWidth: '500px' }}>
            <h2 tw="text-navy">{intro.primary.headline.text}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: intro.primary.description.html,
              }}
              tw="text-ocean mt-5 flex flex-col gap-7 text-lg"
              className="intro-description"></div>
            {intro.primary.button != null ? (
              <div tw="w-full md:w-36 m-auto md:w-auto">
                <LocalizedLink to="/request-meeting">
                  <SmallSolidTealButton tw="w-full md:w-auto mt-8">
                    {intro.primary.button}
                  </SmallSolidTealButton>
                </LocalizedLink>
              </div>
            ) : (
              false
            )}
          </div>
        </div>

        <div className="intro-image" tw="mt-12 md:mt-0">
          <img src={bg} style={{}} tw="lg:absolute lg:w-3/6" />
        </div>
      </IntroWrapper>
    </WatchElementAnimate>
  )
}
export default Intro
