import { graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { withPreview } from 'gatsby-source-prismic'
import React, { useEffect, useState } from 'react'
import 'react-modal-video/css/modal-video.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { styled } from 'twin.macro'
import SEO from '../components/SEO/SEO'
import Benefits from '../components/home/benefits'
import Features from '../components/home/features'
import Industries from '../components/home/industries'
import Innovation from '../components/home/innovation'
import Intro from '../components/home/intro'
import Partners from '../components/home/partners'
import Testimonial from '../components/home/testimonial'
import Testimonial1 from '../components/home/testimonial-1'
import WhoWeAre from '../components/home/who-we-are'
import { LocaleContext } from '../components/layout'
import LocalizedLink from '../components/localized-link'
import AIWork from '../components/services/ai-work'
import WatchElementAnimate from '../components/watch-element-animate'
import background from '../images/Hero_Background_Image_01.svg'
import IntroBackground from '../images/Software_image.svg'
import {
  LargeSolidTurquoiseButton
} from '../utils/styles'

// styles
const HeroWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
  .hero-left {
    @media (min-width: 1024px) {
      max-width: 450px;
      margin-right: 84px;
    }
  }
  @media (max-width: 1023px) {
    padding-top: 120px;
    padding-bottom: 50px;
  }
`
const HomeWrapper = styled.div`
  .testimonial-container {
    position: relative;
    z-index: 10;
  }
  @media (min-width: 769px) {
    .home-intro {
      margin-bottom: 40px;
      margin-top: 20px;
    }
  }
  @media (min-width: 1441px) {
    .home-intro {
      margin-bottom: 80px;
      margin-top: 20px;
    }
  }
`
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en'
  }
  const lang =
    navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return 'en'

  switch (lang) {
    case 'de':
      return 'de'
    default:
      return 'en'
  }
}
// markup
const IndexPage = ({ data: { prismicHome }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (localStorage.getItem('locale') === null && urlLang === 'de') {
      navigate(`/${urlLang}`)
    }
  }, [])
  return (
    <main>
      <SEO
        title={prismicHome.data.meta_title}
        desc={prismicHome.data.meta_description}
        locale={locale}
      />
      <HeroWrapper>
        <div tw="container">
          <div tw="lg:flex justify-between">
            <div tw="md:flex-none whitespace-normal" className="hero-left">
              <div
                  dangerouslySetInnerHTML={{
                    __html: prismicHome.data.hero_headline.html,
                  }}
                  tw="text-navy">
              </div>
              <p tw="text-xl text-ocean py-8">
                {prismicHome.data.hero_subheadline.text}
              </p>
              <div tw="md:hidden my-4">
                <Img
                  fluid={prismicHome.data.hero_image.fluid}
                  objectFit="contain"
                />
              </div>
              <LocalizedLink to="/request-meeting">
                <LargeSolidTurquoiseButton tw="mb-5">
                  {prismicHome.data.hero_button}
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
            </div>
            <div tw="hidden md:block mt-8 md:mt-0 md:flex-grow w-full">
              <Img
                fluid={prismicHome.data.hero_image.fluid}
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </HeroWrapper>
      <HomeWrapper>
        {prismicHome.data.body.map((b, index) => {
          switch (b.slice_type) {
            case 'benefits':
              return <Benefits benefits={b} />
            case 'intro':
              return (
                <div className="home-intro">
                  <Intro intro={b} bg={IntroBackground} />
                </div>
              )
            case 'client_testimonial':
              return (
                <WatchElementAnimate
                  className={`.index__testimonial__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                  <div className={`index__testimonial__animated__${index}`}>
                    <Testimonial testimonial={b.primary} />
                  </div>
                </WatchElementAnimate>
              )
            case 'client_testimonial_1':
              return (
                <WatchElementAnimate
                  className={`.index__testimonial__1__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                  <div className={`index__testimonial__1__animated__${index} testimonial-container`}>
                      <Testimonial1 testimonial={b.primary} />
                  </div>
                </WatchElementAnimate>
              )
            case 'features':
              return <Features features={b.primary} items={b.items} />
            case 'industries':
              return (
                <Industries
                  industries={b.primary}
                  items={b.items}
                  format={'select'}
                  i18n={i18n}
                />
              )
            case 'innovation':
              return (
                <WatchElementAnimate
                    className={`.index__innovation__animated__${index}`}
                    animateName={'animate__fadeInUp'}>
                  <div className={`index__innovation__animated__${index}`}>
                    <Innovation item={b.primary} flow="reverse" i18n={i18n} />
                  </div>
                </WatchElementAnimate>
              )
            case 'partners':
              return <Partners partner={b.primary} items={b.items} />
            case 'lets_make_ai_work':
              return (
                <AIWork
                  ai={b.primary}
                  items={b.items}
                  flow={'row'}
                  i18n={i18n}
                />
              )
            case 'who_we_are':
              return <WhoWeAre who={b.primary} i18n={i18n} />
            case 'full_width_image':
              return  <div tw="container py-8 lg:p-14 relative z-10">
                        <Img fluid={b.primary.image.fluid} />
                      </div>
            default:
              return null
          }
        })}
      </HomeWrapper>
    </main>
  )
}

export default withPreview(IndexPage)

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    prismicHome(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        hero_button
        body {
          ... on PrismicHomeBodyIntro {
            slice_type
            primary {
              description {
                html
              }
              headline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyFeatures {
            slice_type
            primary {
              headline {
                html
              }
              feature_description
            }
            items {
              card_icon {
                fixed(width: 50) {
                  ...GatsbyPrismicImageFixed
                }
              }
              card_description
              card_headline
            }
          }
          ... on PrismicHomeBodyBenefits {
            items {
              text {
                html
              }
            }
            slice_type
          }
          ... on PrismicHomeBodyClientTestimonial {
            primary {
              name
              role
              text
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              link {
                link_type
                type
                lang
                uid
              }
              button
            }
            slice_type
          }
          ... on PrismicHomeBodyClientTestimonial1 {
            primary {
              name
              role
              text
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
          ... on PrismicHomeBodyIndustries {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              name {
                text
              }
              link {
                url
                slug
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
              benefit {
                html
              }
              link {
                url
              }
            }
          }
          ... on PrismicHomeBodyPartners {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              logo {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyImplementation {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              list_headline
              list_description
            }
          }
          ... on PrismicHomeBodyLetsMakeAiWork {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
            }
            items {
              description {
                html
              }
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyWhoWeAre {
            slice_type
            primary {
              top_headline {
                text
              }
              link {
                url
                slug
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicHomeBodyFullWidthImage {
            slice_type
            primary {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyInnovation {
            slice_type
            primary {
              headline {
                text
              }
              description {
                html
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              link {
                url
              }
            }
          }
        }
        hero_subheadline {
          text
        }
        hero_background {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_headline {
          text
          html
        }
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`
