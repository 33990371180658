import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Quote from '../../images/Quote.svg'
import { SmallSolidTealButton } from '../../utils/styles'
import linkResolver from '../../gatsby/linkResolver'
const TestimonialWrapper = styled.div`
  .testimonial-photo {
    width: 500px;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  .testimonial-info {
    @media (min-width: 1024px) {
      margin-top: 29px;
    }
  }
`
const Testimonial = ({ testimonial }) => {
  const link = linkResolver(testimonial.link)
  return (
    <TestimonialWrapper
      tw="container py-8 lg:flex lg:p-14"
      className="index__testimonial__animated">
      <div
          className="testimonial-photo"
          tw="lg:flex-none p-10 rounded-t-lg md:rounded-t-none md:rounded-l-lg">
        <Img fluid={testimonial.image.fluid} />
      </div>
      <div
        className="testimonial-info"
        tw="xl:py-16 md:flex-grow bg-air justify-self-center text-navy pt-8 py-8 px-8 xl:pt-14 xl:px-24 xl:pb-0 rounded-b-lg md:rounded-b-none md:rounded-r-lg text-center md:text-left">
        <img src={Quote} tw="m-auto mb-6 md:ml-0" />
        <h3>{testimonial.text}</h3>
        <div tw="md:flex justify-between mt-8 text-center md:text-left">
          <div>
            <span tw="text-ocean text-lg">{testimonial.name}</span>
            <span tw="block text-ocean text-lg">{testimonial.role}</span>
          </div>
          <div tw="mt-4 md:mt-0">
            <Link to={link}>
              <SmallSolidTealButton>{testimonial.button}</SmallSolidTealButton>
            </Link>
          </div>
        </div>
      </div>
    </TestimonialWrapper>
  )
}
export default Testimonial
